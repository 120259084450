import React from 'react';
import { SectionConfigLayout } from './utils';
import BaseSectionConfig from './BaseSectionConfig';

class LaParisVideoStyle4FormGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onFormGeneralChange(event.target.name, event.target.value);
    }

    render() {
        const { youtube, img, title, body, button, link } = this.props;
        return (
            <form>
                <div className="form-group">
                    <label htmlFor="youtube">Youtube Video ID</label>
                    <input type="text" className="form-control" id="youtube" name="youtube" value={youtube} onChange={this.handleChange} placeholder="Enter your Youtube video ID" />
                </div>
                <div className="form-group">
                    <label htmlFor="img">Image URL</label>
                    <input type="text" className="form-control" id="img" name="img" value={img} onChange={this.handleChange} placeholder="Enter the thumbnail image URL" />
                </div>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input type="text" className="form-control" id="title" name="title" value={title} onChange={this.handleChange} placeholder="Enter the title text" />
                </div>
                <div className="form-group">
                    <label htmlFor="body">Body</label>
                    <textarea className="form-control" id="body" name="body" value={body} onChange={this.handleChange} placeholder="Enter the body text" />
                </div>
                <div className="form-group">
                    <label htmlFor="button">Button</label>
                    <input type="text" className="form-control" id="button" name="button" value={button} onChange={this.handleChange} placeholder="Enter the button text" />
                </div>
                <div className="form-group">
                    <label htmlFor="link">Link</label>
                    <input type="text" className="form-control" id="link" name="link" value={link} onChange={this.handleChange} placeholder="Enter the link URL" />
                </div>
            </form>
        );
    }
}

class LaParisVideoStyle4 extends BaseSectionConfig {
    defaultTitle = "Style 4 / Video Banner";
    scopeName = "LaParisVideoStyle4";

    constructor(props) {
        super(props);

        this.state = this.getStateFromLocalStorage() || {
            youtube: 'SexuSC5_h_s',
            img: 'https://tvlgiao.github.io/bigcommerce-themes/laparis/demo/images/laparis4-banner09.jpg', 
            title: 'laparis video', 
            body: '', 
            button: 'view more', 
            link: '#',
        };
    }

    render() {
        const data = Buffer.from(JSON.stringify(this.state)).toString('base64');
        return (
            <SectionConfigLayout elClass="LaParisVideoStyle4" title={this.title} 
                panels={[
                    {
                        title: "General",
                        element: <LaParisVideoStyle4FormGeneral onFormGeneralChange={this.onFormGeneralChange} {...this.state} />,

                    },
                ]}

                code={`<div class="u-hiddenVisually" data-local-banner-position="banner-laparis-home-vid-s4" data-template="home_vid_s4" data-params="${data}">DO NOT REMOVE THIS LINE</div>`}
            />
        );
    }
}

export default LaParisVideoStyle4;
