import React from 'react';
import { SectionConfigLayout } from './utils';
import BaseSectionConfig from './BaseSectionConfig';

class LaParisTextStyle4FormGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onFormGeneralChange(event.target.name, event.target.value);
    }

    render() {
        const { title, body, button, link } = this.props;
        return (
            <form>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input type="text" className="form-control" id="title" name="title" value={title} onChange={this.handleChange} placeholder="Enter the title text" />
                </div>
                <div className="form-group">
                    <label htmlFor="body">Body</label>
                    <textarea className="form-control" id="body" name="body" value={body} onChange={this.handleChange} placeholder="Enter the body text" />
                </div>
                <div className="form-group">
                    <label htmlFor="button">Button</label>
                    <input type="text" className="form-control" id="button" name="button" value={button} onChange={this.handleChange} placeholder="Enter the button text" />
                </div>
                <div className="form-group">
                    <label htmlFor="link">Link</label>
                    <input type="text" className="form-control" id="link" name="link" value={link} onChange={this.handleChange} placeholder="Enter the link URL" />
                </div>
            </form>
        );
    }
}

class LaParisTextStyle4 extends BaseSectionConfig {
    defaultTitle = "Style 4 / Text Block";
    scopeName = "LaParisTextStyle4";

    constructor(props) {
        super(props);

        this.state = this.getStateFromLocalStorage() || {
            title: 'welcome to LAPARIS super theme',
            body: 'Duis venenatis in urna auctor vestibulum. Praesent quis felis id dui ultricies tempus in sed ligula. Fusce finibus, nunc sit amet. <a href="#">shop the looks</a>',
            button: '',
            link: '',
        };
    }

    render() {
        const data = Buffer.from(JSON.stringify(this.state)).toString('base64');
        return (
            <SectionConfigLayout elClass="LaParisTextStyle4" title={this.title} 
                panels={[
                    {
                        title: "General",
                        element: <LaParisTextStyle4FormGeneral onFormGeneralChange={this.onFormGeneralChange} {...this.state}  />,

                    },
                ]}

                code={`<div class="u-hiddenVisually" data-local-banner-position="banner-laparis-home-txt-s4" data-template="home_txt_s4" data-params="${data}">DO NOT REMOVE THIS LINE</div>`}
            />
        );
    }
}

export default LaParisTextStyle4;
