import React from 'react';
import { SectionConfigLayout } from './utils';
import BaseSectionConfig from './BaseSectionConfig';


class LaParisBanner3Style4FormItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onFormItemChange(event.target.name, event.target.value);
    }

    render() {
        const { img, title, body, button, link } = this.props;
        return (
            <form>
                <div className="form-group">
                    <label htmlFor="img">Image URL</label>
                    <input type="text" className="form-control" id="img" name="img" value={img} onChange={this.handleChange} aria-describedby="imgHelp" placeholder="Enter the banner image URL" />
                    <small id="imgHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input type="text" className="form-control" id="title" name="title" value={title} onChange={this.handleChange} placeholder="Enter the title text" />
                </div>
                <div className="form-group">
                    <label htmlFor="body">Body</label>
                    <textarea className="form-control" id="body" name="body" value={body} onChange={this.handleChange} placeholder="Enter the body text" />
                </div>
                <div className="form-group">
                    <label htmlFor="button">Button</label>
                    <input type="text" className="form-control" id="button" name="button" value={button} onChange={this.handleChange} placeholder="Enter the button text" />
                </div>
                <div className="form-group">
                    <label htmlFor="link">Link</label>
                    <input type="text" className="form-control" id="link" name="link" value={link} onChange={this.handleChange} placeholder="Enter the link URL" />
                </div>
            </form>
        );
    }
}

class LaParisBanner3Style4FormGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onFormGeneralChange(event.target.name, event.target.value);
    }

    render() {
        const { items_count } = this.props;
        return (
            <form>
                <div className="form-group">
                    <label htmlFor="items_count">Number of banner items</label>
                    <input type="number" min="0" max="100" className="form-control" id="items_count" name="items_count" value={items_count} onChange={this.handleChange} placeholder="Enter number of banner items" />
                </div>
            </form>
        );
    }
}

class LaParisBanner3Style4 extends BaseSectionConfig {
    defaultTitle = "Style 4 / Third Row Banners";
    scopeName = "LaParisBanner3Style4";

    constructor(props) {
        super(props);

        this.state = this.getStateFromLocalStorage() || {
            items: [
                { 
                    img: 'https://tvlgiao.github.io/bigcommerce-themes/laparis/demo/images/laparis4-banner05.jpg', 
                    title: 'beach shirt', 
                    body: '', 
                    button: 'see more', 
                    link: '#'
                },
                { 
                    img: 'https://tvlgiao.github.io/bigcommerce-themes/laparis/demo/images/laparis4-banner06.jpg', 
                    title: '50% off', 
                    body: '', 
                    button: '', 
                    link: '#'
                },
            ]
        };
    }

    render() {
        const data = Buffer.from(JSON.stringify(this.state)).toString('base64');
        return (
            <SectionConfigLayout elClass="LaParisBanner3Style4" title={this.title} 
                panels={[
                    {
                        title: "General",
                        element: <LaParisBanner3Style4FormGeneral onFormGeneralChange={this.onFormGeneralChange} heading={this.state.heading} items_count={this.state.items.length} />,

                    },
                    ...this.state.items.map((item, index) => ({
                        title: `Banner ${index + 1}`,
                        element: <LaParisBanner3Style4FormItem onFormItemChange={this.onFormItemChange.bind(this, index)} {...item} />,
                    })),
                ]}

                code={`<div class="u-hiddenVisually" data-local-banner-position="banner-laparis-home-bnr-s4-3" data-template="home_bnr_s4" data-params="${data}">DO NOT REMOVE THIS LINE</div>`}
            />
        );
    }
}

export default LaParisBanner3Style4;
