import React from 'react';
import { SectionConfigLayout } from './utils';
import BaseSectionConfig from './BaseSectionConfig';


class LaParisSliderStyle2FormItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onFormItemChange(event.target.name, event.target.value);
    }

    render() {
        const { img, img_alt, link } = this.props;
        return (
            <form>
                <div className="form-group">
                    <label htmlFor="img">Slider Image URL</label>
                    <input type="text" className="form-control" id="img" name="img" value={img} onChange={this.handleChange} placeholder="Enter the slider image URL" />
                </div>
                <div className="form-group">
                    <label htmlFor="img_alt">Image Alt Text</label>
                    <input type="text" className="form-control" id="img_alt" name="img_alt" value={img_alt} onChange={this.handleChange} placeholder="Enter the image alt text" />
                </div>
                <div className="form-group">
                    <label htmlFor="link">Link</label>
                    <input type="text" className="form-control" id="link" name="link" value={link} onChange={this.handleChange} placeholder="Enter the link URL" />
                </div>
            </form>
        );
    }
}

class LaParisSliderStyle2FormGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onFormGeneralChange(event.target.name, event.target.value);
    }

    render() {
        const { items_count } = this.props;
        return (
            <form>
                <div className="form-group">
                    <label htmlFor="items_count">Number of slider items</label>
                    <input type="number" min="0" max="100" className="form-control" id="items_count" name="items_count" value={items_count} onChange={this.handleChange} placeholder="Enter number of slider items" />
                </div>
            </form>
        );
    }
}

class LaParisSliderStyle2 extends BaseSectionConfig {
    defaultTitle = "Style 2 / Image Slider";
    scopeName = "LaParisSliderStyle2";

    constructor(props) {
        super(props);

        this.state = this.getStateFromLocalStorage() || {
            items: [
                { 
                    img: 'https://tvlgiao.github.io/bigcommerce-themes/laparis/demo/images/banner06.jpg', 
                    img_alt: '', 
                    link: '#'
                },
                { 
                    img: 'https://tvlgiao.github.io/bigcommerce-themes/laparis/demo/images/banner07.jpg', 
                    img_alt: '', 
                    link: '#'
                },
                { 
                    img: 'https://tvlgiao.github.io/bigcommerce-themes/laparis/demo/images/banner08.jpg', 
                    img_alt: '', 
                    link: '#'
                },
                { 
                    img: 'https://tvlgiao.github.io/bigcommerce-themes/laparis/demo/images/banner09.jpg', 
                    img_alt: '', 
                    link: '#'
                },
                { 
                    img: 'https://tvlgiao.github.io/bigcommerce-themes/laparis/demo/images/banner10.jpg', 
                    img_alt: '', 
                    link: '#'
                },
            ]
        };
    }

    render() {
        const data = Buffer.from(JSON.stringify(this.state)).toString('base64');
        return (
            <SectionConfigLayout elClass="LaParisSliderStyle2" title={this.title} 
                panels={[
                    {
                        title: "General",
                        element: <LaParisSliderStyle2FormGeneral onFormGeneralChange={this.onFormGeneralChange} heading={this.state.heading} items_count={this.state.items.length} />,

                    },
                    ...this.state.items.map((item, index) => ({
                        title: `Banner ${index + 1}`,
                        element: <LaParisSliderStyle2FormItem onFormItemChange={this.onFormItemChange.bind(this, index)} {...item} />,
                    })),
                ]}

                code={`<div class="u-hiddenVisually" data-local-banner-position="banner-laparis-home-slider-s2" data-template="home_slider_s2" data-params="${data}">DO NOT REMOVE THIS LINE</div>`}
            />
        );
    }
}

export default LaParisSliderStyle2;
