import React from 'react';
import './App.css';
import LaParisBanner1Style1 from './LaParisBanner1Style1';
import LaParisBrandCarousel from './LaParisBrandCarousel';
import LaParisSliderStyle2 from './LaParisSliderStyle2';
import LaParisBanner1Style3 from './LaParisBanner1Style3';
import LaParisBanner2Style3 from './LaParisBanner2Style3';
import LaParisBanner3Style3 from './LaParisBanner3Style3';
import LaParisTextStyle4 from './LaParisTextStyle4';
import LaParisBanner1Style4 from './LaParisBanner1Style4';
import LaParisBanner2Style4 from './LaParisBanner2Style4';
import LaParisBanner3Style4 from './LaParisBanner3Style4';
import LaParisBanner4Style4 from './LaParisBanner4Style4';
import LaParisBanner5Style4 from './LaParisBanner5Style4';
import LaParisVideoStyle4 from './LaParisVideoStyle4';

function App() {
    const tabs = [
        <LaParisBanner1Style1 title="Style 1 / 3 Banners" />,
        <LaParisBrandCarousel title="Brand Carousel" />,
        <LaParisSliderStyle2 title="Style 2 / Image Slider" />,
        <LaParisBanner1Style3 title="Style 3 / First Row Banners" />,
        <LaParisBanner2Style3 title="Style 3 / Second Row Banners" />,
        <LaParisBanner3Style3 title="Style 3 / Third Row Banners" />,
        <LaParisTextStyle4 title="Style 4 / Text Block" />,
        <LaParisBanner1Style4 title="Style 4 / First Row Banners" />,
        <LaParisBanner2Style4 title="Style 4 / Second Row Banners" />,
        <LaParisBanner3Style4 title="Style 4 / Third Row Banners" />,
        <LaParisBanner4Style4 title="Style 4 / Fourth Row Banners" />,
        <LaParisBanner5Style4 title="Style 4 / Fifth Row Banners" />,
        <LaParisVideoStyle4 title="Style 4 / Video Banner" />,
    ];

    return (
        <div className="App">
            <div className="container-fuild">
                <div className="row App-row">
                    <div className="col-md-auto App-col-sidebar">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            {tabs.map((el, i) =>
                                <a key={i} className={`nav-link ${i === 0 ? 'active' : ''}`} id={`v-pills-${i}-tab`} data-toggle="pill" href={`#v-pills-${i}`} role="tab" aria-controls={`v-pills-${i}`} aria-selected={i === 0 ? 'true' : 'false'}>{el.props.title}</a>
                            )}
                        </div>
                    </div>
                    <div className="col">
                        <div className="tab-content" id="v-pills-tabContent">
                            {tabs.map((el, i) =>
                                <div key={i} className={`tab-pane fade ${i === 0 ? 'show active' : ''}`} id={`v-pills-${i}`} role="tabpanel" aria-labelledby={`v-pills-${i}-tab`}>
                                    {el}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
